import { theme } from '../styles/theme'
import { TextComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'
import { ICSS, createStyles, ITheme, TextStyleParams, StyleProp } from '@codeleap/styles'

const createTextVariant = createStyles<TextComposition>

type FontName = keyof ITheme['typography']['fonts']

const isFontName = (name: string): name is FontName => {
  return theme.typography.fonts[name] !== undefined
}

type TextColors = 'heading' | 'paragraph' | 'caption'

function textStyle(params?: TextStyleParams, color?: TextColors) {
  return createTextVariant(theme => {
    const _color = color ? {
      'heading': theme.colors.neutral10,
      'paragraph': theme.colors.neutral10,
      'caption': theme.colors.neutral10,
    }[color] : undefined

    return {
      text: {
        color: _color,
        ...theme.typography.defaults,
        ...params,
      },
    }
  })
}

export const TextStyles = {
  default: textStyle({
    fontSize: 16,
    fontWeight: '400',
  }, 'paragraph'),
  hx: textStyle({
    fontSize: 72,
    fontWeight: '700',
  }, 'heading'),
  h0: textStyle({
    fontSize: 40,
    fontWeight: '700',
  }, 'heading'),
  h1: textStyle({
    fontSize: 32,
    fontWeight: '700',
  }, 'heading'),
  h2: textStyle({
    fontSize: 24,
    fontWeight: '600',
  }, 'heading'),
  h3: textStyle({
    fontSize: 20,
    fontWeight: '600',
  }, 'heading'),
  h4: textStyle({
    fontSize: 18,
    fontWeight: '600',
  }, 'heading'),
  h5: textStyle({
    fontSize: 16,
    fontWeight: '600',
  }, 'heading'),
  p1: textStyle({
    fontSize: 16,
    fontWeight: '400',
  }, 'paragraph'),
  p2: textStyle({
    fontSize: 14,
    fontWeight: '600',
  }, 'paragraph'),
  p3: textStyle({
    fontSize: 14,
    fontWeight: '400',
  }, 'paragraph'),
  p4: textStyle({
    fontSize: 10,
    fontWeight: '400',
  }, 'paragraph'),
  p5: textStyle({
    fontSize: 10,
    fontWeight: '400',
  }, 'paragraph'),
  extraBold: textStyle({
    fontWeight: '800',
  }),
  bold: textStyle({
    fontWeight: '700',
  }),
  semiBold: textStyle({
    fontWeight: '600',
  }),
  medium: textStyle({
    fontWeight: '500',
  }),
  regular: textStyle({
    fontWeight: '400',
  }),
  light: textStyle({
    fontWeight: '300',
  }),
  extraLight: textStyle({
    fontWeight: '200',
  }),
  thin: textStyle({
    fontWeight: '100',
  }),
  link: createTextVariant((theme) => ({
    text: {
      textDecoration: 'underline',
      color: theme.colors.primary3,
    },
  })),
  ellipsis: createTextVariant(() => ({
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })),
}

StyleRegistry.registerVariants<TextComposition>('Text', TextStyles, (_, style) => {
  const family = isFontName(style?.text?.fontFamily) ? style?.text?.fontFamily : theme.typography.defaults.fontFamily
  
  style.text = style.text || {}

  style.text.fontFamily = theme.typography.fonts[family]

  return style
})

export function textVariant(variant: StyleProp<Record<TextComposition, ICSS>, keyof typeof TextStyles>) {
  return StyleRegistry.styleFor('Text', variant).text
}
