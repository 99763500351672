import { ListComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

const createListVariant = createStyles<ListComposition>

export const ListStyles = {
  default: createListVariant((theme) => {
    return {
      wrapper: {
        minHeight: '30vh',
        ...theme.presets.column,
        ...theme.presets.fullWidth,
        ...theme.presets.relative,
      },
      innerWrapper: {
        ...theme.presets.fullWidth,
      },
      refreshControl: {
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.rounded,
        zIndex: 2,
        ...theme.presets.absolute,
        left: '50%',
        transform: 'translateX(-50%)',
        pointerEvents: 'none',
        ...theme.spacing.padding(0.5),
      },
    }
  }),
  hiddenSeparator: createListVariant(theme => ({
    separator: {
      ...theme.presets.fullWidth,
      height: theme.values.innerSpacing.value,
    },
  })),
  lineSeparators: createListVariant(theme => ({
    separator: {
      height: theme.values.pixel,
      backgroundColor: theme.colors.neutral5,
    },
  })),
}

StyleRegistry.registerVariants('List', ListStyles)
