import { ModalComposition } from '@codeleap/web'
import { textVariant } from './Text'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

export type AlertParts = 'body' | 'actions' | 'option' | `modal${Capitalize<ModalComposition>}`
export type AlertComposition = AlertParts

const createAlertVariant = createStyles<AlertComposition>

const WIDTH = 450

export const AlertStyles = {
  default: createAlertVariant((theme) => ({
    option: {
      ...theme.presets.fullWidth,
      ...theme.presets.flex,
      borderRadius: theme.borderRadius.medium,
    },
    body: {
      ...textVariant('p1'),
      ...theme.presets.textCenter,
    },
    actions: {
      ...theme.presets.fullWidth,
      ...theme.spacing.marginTop(2),
      gap: theme.spacing.value(2),
    },
    modalTitle: {
      ...textVariant('h4'),
    },
    'modalBox': {
      maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,
      width: WIDTH,

      ...theme.spacing.padding(4),

      [theme.media.down('mobile')]: {
        ...theme.spacing.padding(2),
      },
    },
  })),
}

StyleRegistry.registerVariants('Alert', AlertStyles)
