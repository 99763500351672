import { QueryKeys } from './queryKeys'
import { useSession } from './useSession'
import { isEmailAvailable } from './views'
import { SocialProviderTypes } from './social'
import { Profile } from '@/types'
import { AuthErrors } from './errors'
import * as auth from 'firebase/auth';
import { firebase } from '@/services/firebase'
import { appStatusStore } from '@/stores'

export function useSignup() {
  const { createProfile } = useSession()

  const sendEmailVerification = async (user:auth.User) => {
    logger.debug('Sending email verification', user, 'Auth')
    auth.sendEmailVerification(user)
  }

  const signup = async (data:Partial<Profile>, source:SocialProviderTypes|'email' = 'email') => {
    appStatusStore.set('loading')

    try {
      const emailAvailable = await isEmailAvailable(data.email)
      logger.debug('Email available', emailAvailable, 'Auth')
      let currentUser = firebase.auth.currentUser
      logger.debug('Firebase currentUser', currentUser, 'Sign Up')

      currentUser?.reload?.()

      logger.debug('Firebase currentUser (after reload)', currentUser, 'Sign Up')

      if (!emailAvailable) {
        AuthErrors.exception('auth/email-in-use')
      }

      appStatusStore.set('loading')

      try {
        if (source === 'email') {
          logger.debug('Creating user with email and password', data, 'Sign Up')
          const newUser = await firebase.auth.createUserWithEmailAndPassword(data.email, data.password)
          currentUser = newUser.user
        }

        currentUser?.reload?.()

        if (!currentUser?.emailVerified) {
          await sendEmailVerification(currentUser)
        }

        currentUser?.reload?.()

      } catch (err) {
        AuthErrors.exception(err)
      }

      logger.debug('Creating profile', data, 'Sign Up')

      await createProfile.mutateAsync(data)
      await QueryKeys.me.refresh()

      appStatusStore.set('done')

      return {
        user: currentUser,
      }
    } catch (err) {
      appStatusStore.set('idle')
      AuthErrors.onError(err, 'signup')
    }
  }

  return { signup, sendEmailVerification }
}
