import { CropPickerComposition } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

const createCropPickerVariant = createStyles<CropPickerComposition>

export const CropPickerStyles = {
  default: createCropPickerVariant(theme => ({
    previewSize: {
      maxHeight: '70vh',
      [theme.media.down('mobile')]: {
        maxHeight: 'unset',
        maxWidth: '80vh',
      },
    },
    confirmButtonWrapper: {
      ...theme.presets.fullWidth,
    },
  })),
}

StyleRegistry.registerVariants('CropPicker', CropPickerStyles)
