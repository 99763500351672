import { React, Settings } from '@/config'
import { toggleOnboarding, useDebug } from '@/utils'
import { ActionIcon, Button, Modal, View, LanguageSelector } from '@/components'
import { createStyles } from '@codeleap/styles'
import { appStatusStore } from '@/stores'
import { useModal } from '@codeleap/hooks'

export const DebugButtons = ({ toggle = () => null }) => {
  const debug = useDebug()

  return <View style={['gap:2', 'column']}>
    <Button
      debugName={'Submit logs'}
      text={'Submit logs'}
      onPress={debug.submitLogs}
    />

    <Button
      debugName={'Test Sentry crash'}
      text={'Test Sentry crash'}
      onPress={debug.testSentryCrash}
    />

    <Button
      debugName={'Test undefined.fuuuuu()'}
      text={'Test undefined.fuuuuu()'}
      onPress={debug.testUndefinedFunctionCall}
    />

    <Button
      debugName={'Test try catch'}
      text={'Test try catch'}
      onPress={debug.testTryCatch}
    />

    <Button
      debugName={'Test throwing an error'}
      text={'Test throwing an error'}
      onPress={debug.testThrowError}
    />

    <Button
      debugName={'Test logger.warn'}
      text={'Test logger.warn'}
      onPress={debug.testLoggerWarn}
    />

    <Button
      debugName={'Test logger.error'}
      text={'Test logger.error'}
      onPress={debug.testLoggerError}
    />

    <Button
      debugName={'Test logging circular dependency'}
      text={'Test logging circular dependency'}
      onPress={debug.testLoggingCircularDeps}
    />

    <Button
      debugName={'Test onboarding'}
      text={'Test onboarding'}
      onPress={() => {
        toggle()
        toggleOnboarding(true)
      }}
    />

    <LanguageSelector />
  </View>
}

export const DebugModal = () => {
  const modal = useModal()
  const isDev = appStatusStore.state.use(state => state.isDev)

  if (!isDev && !Settings.Environment.IsDev) return null

  return <>
    <ActionIcon
      icon='bug'
      onPress={modal.open}
      debugName={'Debug modal button'}
      style={['bg:primary3', styles.bugButton]}
      iconProps={{ style: ['medium'] }}
    />

    <Modal
      visible={modal.visible}
      toggle={modal.toggle}
      debugName={'Debug modal'}
      title='Debug'
      showClose
      style='centered'
    >
      <DebugButtons toggle={modal.toggle} />
    </Modal>
  </>
}

const styles = createStyles(theme => ({
  bugButton: {
    ...theme.presets.fixed,
    right: theme.spacing.value(2),
    bottom: theme.spacing.value(12),
    zIndex: 9,
  },
  wrapper: {
    ...theme.presets.center,
    backgroundColor: theme.colors.primary4,
    ...theme.spacing.padding(1),
    borderRadius: theme.borderRadius.small,
  },
}))
