import { View, Page, Image, PageProps, ImageProps } from '@/components'
import { React, AppImages } from '@/config'
import { useMediaQueryDown } from '@/utils'
import { createStyles } from '@codeleap/styles'

const BREAKPOINT = 'tabletSmall'

type AuthLayoutProps = {
  title: string
  children: React.ReactNode
  image?: ImageProps['source']
  pageProps?: Partial<PageProps>
}

export const AuthLayout = ({ title, children, image = null, pageProps }: AuthLayoutProps) => {
  const isMobile = useMediaQueryDown(BREAKPOINT)
  const sourceImage = image || (isMobile ? AppImages.HomeImage : AppImages.SignUp)

  return (
    <Page centerContent={false} {...pageProps} pageTitle={title}>
      <View style={styles.wrapper}>
        <View style={styles.leftContainer}>
          <Image
            source={sourceImage}
            style={styles.image}
            objectFit='contain'
            alt='Auth illustration'
          />
        </View>

        <View style={styles.rightContainer}>
          {children}
        </View>
      </View>
    </Page>
  )
}

const styles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    ...theme.presets.fullWidth,
    ...theme.presets.row,
    overflow: 'hidden',

    [theme.media.down(BREAKPOINT)]: {
      flexDirection: 'column-reverse',
    },
  },
  leftContainer: {
    ...theme.presets.flex,
    ...theme.presets.alignCenter,
    backgroundColor: theme.colors.primary1,
  },
  rightContainer: {
    width: '50vw',
    ...theme.spacing.padding(4),
    ...theme.presets.center,

    [theme.media.down(BREAKPOINT)]: {
      width: '100vw',
      maxWidth: '100vw',
      ...theme.spacing.padding(2),
    },
  },
  image: {
    width: '90%',
    height: '100%',

    [theme.media.down(BREAKPOINT)]: {
      width: '100%',
    },
  },
}))
