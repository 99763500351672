import { appStatusStore } from '@/stores'

export const useAppStatus = () => {
  const status = appStatusStore.state.use(state => state.status)

  const showingFeedback = status === 'done' || status === 'loading'

  const showingSplash = status === 'splash'

  return {
    showingFeedback,
    showingSplash,
    status,
  }
}
