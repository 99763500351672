import { TooltipComposition } from '@codeleap/web'
import { keyframes } from '@emotion/react'
import { StyleRegistry } from '../styles'
import { createStyles, ICSS } from '@codeleap/styles'

const createTooltipVariant = createStyles<TooltipComposition>

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }    
`
const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
export const TooltipStyles = {
  default: createTooltipVariant((theme) => {

    const contentDefaultStyle: ICSS = {
      position: 'relative',
      ...theme.spacing.padding(2),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral2,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      fill: theme.colors.neutral2,
    }

    return {
      content: {
        ...contentDefaultStyle,
      },
      'content:left': {
        ...contentDefaultStyle,
        animationName: slideRightAndFade,
      },
      'content:right': {
        ...contentDefaultStyle,
        animationName: slideLeftAndFade,
      },
      'content:top': {
        ...contentDefaultStyle,
        animationName: slideDownAndFade,
      },
      'content:bottom': {
        ...contentDefaultStyle,
        animationName: slideUpAndFade,
      },
    }
  }),
  rounded: createTooltipVariant((theme) => ({
    content: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  bare: createTooltipVariant((theme) => ({
    content: {
      ...theme.spacing.padding(0),
    },
  })),
  small: createTooltipVariant((theme) => ({
    content: {
      ...theme.spacing.padding(1),
    },
  })),
  large: createTooltipVariant((theme) => ({
    content: {
      ...theme.spacing.padding(4),
    },
  })),
  success: createTooltipVariant((theme) => ({
    content: {
      backgroundColor: theme.colors.positive1,
    },
  })),
  overlap: createTooltipVariant(theme => ({
    content: {
      zIndex: 9999,
    },
  })),
}

StyleRegistry.registerVariants('Tooltip', TooltipStyles)
