import { Settings, StyleSheets } from '@/config'
import { View, Text, Link } from '@/components'
import { format } from 'date-fns'
import { StylesOf } from '@codeleap/types'

export type FooterContactProps = {
  styles?: StylesOf<StyleSheets.FooterComposition>
}

export const FooterContact = (props: FooterContactProps) => {
  const { styles } = props

  const year = format(new Date(), 'yyyy')

  return (
    <View style={styles.contactWrapper}>
      <Link
        text={Settings.ContactINFO.ContactEMAIL}
        to={`mailto:${Settings.ContactINFO.ContactEMAIL}`}
        style={styles.contactText}
      />
      <Link
        text={Settings.ContactINFO.ContactPHONE}
        to={`tel:${Settings.ContactINFO.ContactPHONE}`}
        style={styles.contactText}
      />
      <Text
        text={`©${year} CodeLeap Ltd.`}
        style={styles.contactText}
      />
      <Text
        text={'Made with ♥️ in London, UK'}
        style={styles.contactText}
      />
    </View>
  )
}
