import { ActivityIndicatorComposition } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

const createActivityIndicatorVariant = createStyles<ActivityIndicatorComposition>

export const ActivityIndicatorStyles = {
  default: createActivityIndicatorVariant((theme) => ({
    wrapper: {
      height: theme.values.iconSize[6],
      width: theme.values.iconSize[6],
      zIndex: 99,
    },
  })),
  tiny: createActivityIndicatorVariant((theme) => ({
    wrapper: {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
    },
  })),
  small: createActivityIndicatorVariant((theme) => ({
    wrapper: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
  })),
  medium: createActivityIndicatorVariant((theme) => ({
    wrapper: {
      height: theme.values.iconSize[5],
      width: theme.values.iconSize[5],
    },
  })),
}

StyleRegistry.registerVariants('ActivityIndicator', ActivityIndicatorStyles)
