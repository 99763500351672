import React from 'react'
import { useStylesFor } from '@codeleap/web'
import { StyleSheets, StyleRegistry, Settings } from '@/config'
import { StyledProp, StyledComponent } from '@codeleap/styles'
import { ImageComposition } from '@/config/stylesheets/Image'
import { StaticImageData } from 'next/image'
import NextImage, { ImageProps as NextImageProps } from 'next/image'

export type ImageProps =
  Omit<Partial<NextImageProps>, 'style'> &
  {
    source: StaticImageData
    alt: string
    objectFit?: React.CSSProperties['objectFit']
    style?: StyledProp<ImageComposition>
  }

export const Image: StyledComponent<typeof StyleSheets.ImageStyles, ImageProps> = (props) => {
  const {
    source,
    objectFit = 'contain',
    style,
    alt = `${Settings.AppName} Image`,
    className,
    ...rest
  } = props

  const styles = useStylesFor(Image.styleRegistryName, style)

  return (
    <div css={styles.wrapper} className={className}>
      <NextImage
        {...rest}
        alt={alt}
        fill={true}
        src={source}
        style={{ position: 'absolute', objectFit }}
      />
    </div>
  )
}

Image.styleRegistryName = 'Image'
Image.elements = ['wrapper']

StyleRegistry.registerComponent(Image)
