import { OSAlert } from '@/config'
import { APIClient } from '@/services'

import * as Sentry from '@sentry/nextjs'

const MODULE_NAME = 'Debug Buttons'

const testErrorObject = {
  myObject: 'myValue',
  myArray: [1, 2, 3],
  myFunction: () => {},
  appOpenTime: new Date(),
  profile: {},
  testerIdentifier: 'none',
}

export const useDebug = () => {
  const profile = APIClient.Session.useProfile()

  return {
    submitLogs: () => {
      logger.log('User manually submitted logs', testErrorObject, MODULE_NAME)
      Sentry.captureMessage(`User manually submitted logs: ${profile?.data?.email}`)
      OSAlert.info({ title: 'Logs submitted', body: 'Your logs have been submitted. Thank you!' })
    },
    testSentryCrash: () => {
      Sentry.captureException(new Error('Test Sentry crash'))
      OSAlert.info({ title: 'Sentry Crash', body: 'Crash event sent!' })
    },
    testUndefinedFunctionCall: () => {
      //@ts-ignore // NOTE this is meant to crash indeed, silly TS
      undefined.fuuuuu()
    },
    testTryCatch: () => {
      try {
        //@ts-ignore // NOTE this is meant to crash indeed, silly TS
        undefined.fuuuuu()
      } catch (e) {
        logger.error('Caught test error', e, MODULE_NAME)
      }
    },
    testThrowError: () => {
      logger.log('Test logging stuff', testErrorObject, MODULE_NAME)
      throw `Fuck I'm a test error`
    },
    testLoggerWarn: () => {
      logger.warn('Test warning', testErrorObject, MODULE_NAME)
    },
    testLoggerError: () => {
      logger.error('Test error', testErrorObject, MODULE_NAME)
    },
    testLoggingCircularDeps: () => {

      const myObjectA = { name: 'A', pointerToB: null }
      const myObjectB = { name: 'B', pointerToA: myObjectA }
      myObjectA.pointerToB = myObjectB

      logger.log('Test circular pointer error', { codeFuck: { myObjectA, myObjectB }, testErrorObject }, MODULE_NAME)
      logger.error('Test circular pointer error', { codeFuck: { myObjectA, myObjectB }, testErrorObject }, MODULE_NAME)

    },

  }
}
