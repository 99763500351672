import { createForm } from '@codeleap/form'
import { login } from './login'
import { fakeProfile } from '../testers'
import { format, subYears } from 'date-fns'
import { DefaultDateFormat } from '../../constants'

export const signup = (isSocial = false) => {
  const loginForm = login()

  return createForm('signup', {
    email: {
      ...loginForm.config.email,
      defaultValue: fakeProfile.email,
    },
    birthday: {
      label: 'Date of Birth',
      type: 'date',
      defaultValue: fakeProfile.birthday,
      // @ts-ignore
      placeholder: format(subYears(new Date(), 30), DefaultDateFormat),

    },
    password: {
      ...loginForm.config.password,
      validate: isSocial ? undefined : loginForm.config.password.validate,
      defaultValue: fakeProfile.password,
    },
    first_name: {
      type: 'text',
      label: 'First Name',
      placeholder: 'John',
      defaultValue: fakeProfile.first_name,
    },
    last_name: {
      type: 'text',
      label: 'Last Name',
      placeholder: 'Doe',
      defaultValue: fakeProfile.last_name,
    },
    repeatPassword: {
      ...loginForm.config.password,
      defaultValue: fakeProfile.repeatPassword,
      validate: !isSocial ? (repeatPassword, { password }) => {
        const isValid = repeatPassword === password
        return {
          valid: isValid,
          message: isValid ? '' : "Passwords don't match",
        }
      } : null,
    },
    terms: {
      type: 'checkbox',
      label: 'Accept terms and conditions',
      defaultValue: true,
      validate: (value) => {
        const accepted = value
        return {
          valid: accepted,
          message: accepted ? '' : 'You need to accept our Terms & Conditions to register',
        }
      },
    },
    avatar: {
      type: 'file',
    },
    tester: loginForm.config.tester,
  })
}

export const socialSignup = () => signup(true)
