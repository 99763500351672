import { I18N, OSAlert } from '@/config'
import { AppAuthErrors } from '@codeleap/auth'

const errors = {
  'auth/wrong-password': () => I18N.t('authErrors.credentialsError'),
  'auth/not-registered': () => I18N.t('authErrors.notRegistered'),
  'auth/requires-recent-login': () => I18N.t('authErrors.requiresLogin'),
  'auth/invalid-login-credentials': () => I18N.t('authErrors.credentialsError'),
  'auth/too-many-requests': () => I18N.t('authErrors.manyAttempts'),
  'auth/email-in-use': () => I18N.t('authErrors.emailInUse'),
  'auth/email-not-found': () => I18N.t('authErrors.emailNotFound'),
  'auth/email-already-in-use': () => I18N.t('authErrors.emailInUse'),
  'auth/invalid-email': () => I18N.t('authErrors.credentialsError'), 
  'auth/user-disabled': () => I18N.t('authErrors.userNotFound'), 
  'auth/user-not-found': () => I18N.t('authErrors.credentialsError'), 
  'auth/user-token-expired': null,
  'auth/missing-email': () => I18N.t('authErrors.emailNotFound'),
}

export const AuthErrors = new AppAuthErrors(errors, (err, module, args) => {
  const msg = err?.msg?.(args)

  if (!!err && !!msg) {
    OSAlert.error({ title: I18N.t('error'), body: msg })
  } else if (!err) {
    OSAlert.error({ title: I18N.t('error'), body: I18N.t('authErrors.genericError') })
  }

  logger.error(`${module ?? 'Auth'} Error`, err, 'Auth')
})
