import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

export type ImageComposition = 'wrapper'

const createImageVariant = createStyles<ImageComposition>

export const ImageStyles = {
  default: createImageVariant(() => ({
    wrapper: {
      position: 'relative',
      overflow: 'hidden'
    },
  })),
  skeleton: createImageVariant(theme => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  round: createImageVariant(theme => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
}

StyleRegistry.registerVariants('Image', ImageStyles)
