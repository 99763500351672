import { View } from '@/components'
import { ViewProps, useStylesFor } from '@codeleap/web'
import { concatStyles, StyledProp, StyledComponent } from '@codeleap/styles'
import { StyleSheets } from '@/config'
import { NavigationComponent, useNavigation } from '..'

type GroupProps = Partial<Omit<ViewProps, 'style'>> & {
  style?: StyledProp<StyleSheets.NavigationComposition>
}

export const Group: StyledComponent<typeof StyleSheets.NavigationStyles, GroupProps> = (props) => {
  const {
    children,
    style,
    ...rest
  } = props

  const { style: containerStyle } = useNavigation()

  const styles = useStylesFor(NavigationComponent.styleRegistryName, concatStyles<StyleSheets.NavigationComposition>([containerStyle, style]))

  return (
    <View {...rest} style={styles.group}>
      {children}
    </View>
  )
}
