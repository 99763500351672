import { ReactQuery } from '@codeleap/query'
import { QueryKeys } from './queryKeys'
import { retrieve } from './views'
import { Profile } from '@/types'

import '../queryClient'

type UseProfileOptions = Partial<ReactQuery.UseQueryOptions<Profile>>

export function useProfile(options?: UseProfileOptions) {
  const query = ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
    retry: false,
    enabled: false,
    ...options,
  })

  return {
    ...query,
    isLoggedIn: !!query.data,
  }
}
