export { default as LogoImage } from './codeleap_logo_black.png'
export { default as Placeholder } from './placeholder.png'
export { default as loadingAnimation } from '../images/loadingAnimation.json'
export { default as LogoTemplate } from './logo-template.png'
export { default as LogoTemplateMobile } from './logo-template-mobile.png'
export { default as Onboarding1 } from './onboarding1.png'
export { default as Onboarding2 } from './onboarding2.png'
export { default as RocketImage } from './rocket.jpeg'
export { default as HomeImage } from './home.png'
export { default as SignUp } from './SignUp.png'
export { default as EmptyBox } from './emptyBox.png'
export { default as MagnifyingGlass } from './magnifying_glass.png'
