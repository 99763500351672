import format from 'date-fns/format'

const getMaxDate = (minAge: number) => {
  const now = new Date()
  const maxDate = new Date()
  maxDate.setFullYear(now.getFullYear() - minAge)
  return maxDate
}

const getMinDate = (maxAge: number) => {
  const now = new Date()
  const minDate = new Date()
  minDate.setFullYear(now.getFullYear() - maxAge)
  return minDate
}

export const getBirthdayConstraints = (minAge = 16, maxAge = 100) => {
  const minDate = getMinDate(maxAge)
  const maxDate = getMaxDate(minAge)

  return {
    minDate,
    maxDate,
    startDate: minDate,
  }
}

export const formatPostDate = (date: string|Date) => {
  if (!date) return ''

  const parsedDate = date instanceof Date ? date : new Date(date)

  if (isNaN(parsedDate.getTime())) return ''

  return format(parsedDate, 'MMM yy')
}
