import { waitFor } from '@codeleap/utils'
import { globalState } from '@codeleap/store'
import { LocalStorage } from '@/config'
import { TypeGuards } from '@codeleap/types'

const onboardingState = globalState({
  page: 0,
  visible: false,
})

export function useOnboarding() {
  const { page, visible } = onboardingState.use()

  const setPage = (newPage: number) => {
    LocalStorage.setItem('ONBOARDING', newPage)
    onboardingState.set({ page: newPage })
  }

  const next = () => {
    const currentPage = onboardingState.value.page

    setPage(currentPage + 1)
  }

  const previous = () => {
    const currentPage = onboardingState.value.page

    const isFirst = currentPage <= 0

    if (!isFirst) {
      setPage(currentPage - 1)
    }
  }

  const finish = async () => {
    LocalStorage.removeItem('ONBOARDING')
    onboardingState.set({ visible: false })
    await waitFor(1000)
    onboardingState.set({ page: 0 })
  }

  return {
    page,
    next,
    previous,
    goTo: setPage,
    finish,
    visible,
  }
}

export async function checkOnboarding() {
  const currentPage = LocalStorage.getItem('ONBOARDING')

  const pendent = TypeGuards.isNumber(currentPage)

  if (pendent) {
    onboardingState.set({ page: currentPage })
    await waitFor(500)
    onboardingState.set({ visible: true })
  }
}

export function toggleOnboarding(visible: boolean) {
  onboardingState.set({ visible })
}
