import { navigation, React } from '@/config'
import { APIClient } from '@/services'
import { onMount, onUpdate, useUnmount } from '@codeleap/hooks'
import { waitFor } from '@codeleap/utils'
import { AuthErrors } from './errors'
import { QueryKeys } from './queryKeys'
import { firebase } from '@/services/firebase'
import { appStatusStore } from '@/stores'

export function useVerifyEmail(root = false) {
  const [isEmailVerified, setIsEmailVerified] = React.useState(false)
  const intervalEmailCheck = React.useRef(null)
  const profile = APIClient.Session.useProfile()

  const cancelEmailCheck = () => {
    if (intervalEmailCheck.current != null) {
      clearInterval(intervalEmailCheck.current)
      intervalEmailCheck.current = null
    }
  }

  const registryEmailCheck = () => {
    if (intervalEmailCheck.current == null) {
      intervalEmailCheck.current = setInterval(() => {
        checkEmail()
      }, 1000)
    }
  }

  async function resendEmailConfirmation() {
    appStatusStore.set('loading')

    try {
      await firebase.auth.sendEmailVerification(
        firebase.auth.currentUser
      )

      appStatusStore.set('done')
    } catch (err) {
      appStatusStore.set('idle')
      AuthErrors.onError(err, 'Resend email - useVerifyEmail')
    }
  }

  const checkEmail = async () => {
    
    let user = firebase.auth.currentUser

    if (!user) {
      return
    }

    firebase.auth.currentUser?.reload?.()

    user = firebase.auth.currentUser

    if (user?.emailVerified) {
      setIsEmailVerified(true)
      cancelEmailCheck()
    }
  }

  onUpdate(() => {
    if (root && isEmailVerified) {
      cancelEmailCheck()
    }
  }, [isEmailVerified])

  onMount(() => {
    if (root) {
      registryEmailCheck()
    }
  })

  useUnmount(() => {
    if (root) {
      cancelEmailCheck()
    }
  })

  const cancelVerification = async () => {
    cancelEmailCheck()

    appStatusStore.set('splash')

    await waitFor(250)

    navigation.navigate('Home')

    APIClient.Session.deleteProfile(profile?.data?.id)
    QueryKeys.me.setData(null)

    await waitFor(1500)

    firebase.auth.signOut()

    appStatusStore.set('idle')
  }

  return {
    resendEmailConfirmation,
    checkEmail,
    isEmailVerified,
    cancelVerification,
  }
}
