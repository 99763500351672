import React from 'react'
import { Button, Text, View } from '@/components'
import { AnyFunction } from '@codeleap/types'

type VerifyEmailBeforeForm = {
  resendEmailConfirmation: AnyFunction
  cancelVerification: AnyFunction
}

export const VerifyEmailBeforeForm = ({ resendEmailConfirmation, cancelVerification }) => {
  const openEmail = () => {
    window.open('mailto:', '_blank', 'noopener,noreferrer')
  }

  const onSendEmail = () => {
    resendEmailConfirmation()
  }

  return (
    <View style={['flex', 'column']}>
      <Text
        style={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={'Verify your email address'}
      />

      <Text
        style={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={`Click the link in the email to confirm your address. Don't forget to check your spam folder.`}
      />

      <View style={['column', 'gap:2', 'marginTop:auto']}>
        <View style={['row', 'gap:1']}>
          <Button
            text={'Open your email'}
            onPress={openEmail}
            debugName={'Open email'}
            style={['marginHorizontal:auto', 'fullWidth', 'large']}
          />
          <Button
            text={'Cancel'}
            onPress={cancelVerification}
            debugName={'Cancel email verify'}
            style={['marginHorizontal:auto', 'fullWidth', 'large', 'outline']}
          />
        </View>

        <Button
          onPress={onSendEmail}
          debugName={'Resend verification'}
          style={['minimal', 'large', 'row', 'gap:1', 'alignCenter', 'justifyCenter']}
        >
          <Text text={"Didn't receive?"} style={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={'Resend email'} style={['medium', 'bold', 'color:primary3']} />
        </Button>
      </View>
    </View>
  )
}
