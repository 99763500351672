import { textVariant } from './Text'
import { LogoComposition } from './Logo'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

export type FooterComposition =
  'wrapper' |
  'innerWrapper' |
  `logo${Capitalize<LogoComposition>}` |
  'separator' |
  'content' |
  'topContent' |
  'bottomContent' |
  'contactWrapper' |
  'contactText' |
  'disclaimersWrapper' |
  'disclaimerText' |
  'socialMediaWrapper' |
  'navigateWrapper' |
  'navigateColumn' |
  'navigateTitle' |
  'navigateLink'

const createFooterVariant = createStyles<FooterComposition>

const minHeight = 420
const logoSize = 128
const BREAKPOINT = 'tabletSmall'

export const FooterStyles = {
  default: createFooterVariant((theme) => ({
    wrapper: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      backgroundColor: theme.colors.primary3,
      minHeight: minHeight,
      ...theme.presets.fullWidth,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(2),
      },
    },
    innerWrapper: {
      ...theme.presets.column,
    },
    logoWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
      marginBottom: theme.spacing.value(5),
      cursor: 'auto',

      [theme.media.down(BREAKPOINT)]: {
        alignSelf: 'center',
      },
    },
    logoImage: {
      width: logoSize,
    },
    separator: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.primary5,
      height: 2,
    },
    content: {
      ...theme.presets.column,
      ...theme.presets.flex,
      gap: theme.spacing.value(5),
    },
    topContent: {
      ...theme.presets.row,
      ...theme.presets.flex,
      ...theme.presets.justifySpaceBetween,
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    bottomContent: {
      ...theme.presets.row,
      ...theme.presets.justifySpaceBetween,
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    disclaimersWrapper: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
    },
    disclaimerText: {
      ...textVariant('p3'),
      color: theme.colors.neutral9,
      display: 'inline-block',

      [theme.media.down(BREAKPOINT)]: {
        alignSelf: 'center',
        ...theme.presets.textCenter,
      },
    },
    navigateWrapper: {
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
        gap: theme.spacing.value(3),
      },
    },
    navigateColumn: {
      ...theme.presets.column,
      gap: theme.spacing.value(2),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.alignCenter,
      },
    },
    navigateTitle: {
      ...textVariant('p3'),
      color: theme.colors.neutral9,
    },
    navigateLink: {
      ...textVariant('h5'),
      color: theme.colors.neutral10,
      textDecoration: 'none',
    },
    contactWrapper: {
      ...theme.presets.column,
      ...theme.presets.fullHeight,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignEnd,
      gap: theme.spacing.value(1),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.alignCenter,
      },
    },
    contactText: {
      ...textVariant('p1'),
      color: theme.colors.neutral10,
      fontWeight: '300',
      textDecoration: 'none',
    },
    socialMediaWrapper: {
      gap: theme.spacing.value(2),
      ...theme.presets.center,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.fullWidth,
      },
    },
  })),
}

StyleRegistry.registerVariants('Footer', FooterStyles)
