import { createSettings } from '@codeleap/utils'
import Obfuscate from './Obfuscate'
import { IS_SSR } from './constants'

export const IsDevEnv = process.env.NODE_ENV === 'development'

const ENV = IsDevEnv ? 'development' : 'production'

const _Settings = createSettings({
  AppName: 'Template',
  CompanyName: 'Example Ltd.',
  Description: 'This is a template',
  AppConfig: {
    emailVerificationEnabled: true,
    signupCompleteVerificationEnabled: true,
  },
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Images: {
    Aspect: {
      default: 1,
    },
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Website: {
    ProductionURL: 'http://template.codeleap.co.uk/',
    DevelopmentURL: 'http://template.codeleap.co.uk/',
  },
  Fetch: {
    ProductionURL: 'https://dev.codeleap.co.uk/',
    DevelopmentURL: 'https://dev.codeleap.co.uk/',
    ViewHTMLErrors: true,
  },
  Social: {
    FacebookURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
  },
  ContactINFO: {
    Website: 'codeleap.co.uk',
    TermsAndPrivacy: 'https://codeleap.co.uk',
    SupportEMAIL: 'support@codeleap.co.uk',
    ContactEMAIL: 'hello@codeleap.co.uk',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    DeviceIdentifier: IS_SSR ? '' : window?.navigator?.userAgent,
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',

    ],
    Obfuscate,
    ComponentLogging: true,
  },
  Sentry: {
    dsn: 'https://e929273c465943e98c540b1839a72afb@o309198.ingest.sentry.io/4505370694975488',
    tracesSampleRate: IsDevEnv ? 1.0 : 0.4,
    environment: ENV,
    debug: IsDevEnv,
    enabled: !IsDevEnv,
    release: '1.0',
  },
  Slack: {
    echo: {
      icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
      token: 'xoxb-622265672359-1248324007429-Ts31vPT8jCNh7L99xtdbOgQB',
      channel: '#_dev_logs',
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        '268760770384-ob7jfjntuorgqaj2tt2mvnqdokd634et.apps.googleusercontent.com',
    },
    AppleSignIn: {
      ServiceClientId: '',
      RedirectURI: '',
    },
    FacebookSDK: {
      AppId: '1130448934268035',
    },
  },
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(_Settings.AppName, 'base64')}`
}

export const Settings = _Settings
