import React from 'react'
import { View, Logo, CenterWrapper, CenterWrapperProps } from '@/components'
import { FooterContact, FooterDisclaimers, FooterNavigate, FooterSocialMedia } from './Sections'
import { StyledComponent, StyledProp, useNestedStylesByKey } from '@codeleap/styles'
import { StyleSheets, StyleRegistry } from '@/config'
import { useStylesFor } from '@codeleap/web'

export type FooterProps =
  Omit<CenterWrapperProps, 'style'> &
  {
    style?: StyledProp<StyleSheets.FooterComposition>
  }

export const FooterComponent: StyledComponent<typeof StyleSheets.FooterStyles, FooterProps> = (props) => {
  const { style, ...rest } = props

  const styles = useStylesFor(FooterComponent.styleRegistryName, style)

  const logoStyles = useNestedStylesByKey('logo', styles)

  return (
    <CenterWrapper
      {...rest}
      style={{
        wrapper: styles.wrapper,
        innerWrapper: styles.innerWrapper,
      }}
    >
      <Logo
        debugName='footer:logo'
        style={logoStyles}
        responsive={false}
        switchServerOnPress
      />

      <View style={styles.content}>
        <View style={styles.topContent}>
          <FooterNavigate styles={styles} />
          <FooterContact styles={styles} />
        </View>

        <View style={styles.separator} />

        <View style={styles.bottomContent}>
          <FooterDisclaimers styles={styles} />
          <FooterSocialMedia styles={styles} />
        </View>
      </View>
    </CenterWrapper>
  )
}

export const Footer = React.memo(FooterComponent, () => true)

FooterComponent.styleRegistryName = 'Footer'

FooterComponent.elements = [
  'wrapper',
  'innerWrapper',
  'logo',
  'separator',
  'content',
  'topContent',
  'bottomContent',
  'contact',
  'disclaimersWrapper',
  'disclaimerText',
  'socialMediaWrapper',
  'navigate',
]

StyleRegistry.registerComponent(FooterComponent)
