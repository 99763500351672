import { LocalStorage } from '@/config'
import { appStatusStore } from '@/stores'
import { onMount, useState } from '@codeleap/hooks'

const CHANGE_SERVER_COUNT = 10

export function useToggleDevMode(readMode = false) {
  const isDevMode = appStatusStore.state.use(state => state.isDev)
  const [numberOfPresses, setPresses] = useState(0)
  const [loaded, setLoaded] = useState(false)

  function onChangePress(currentValue: number) {
    const newValue = currentValue + 1
    if (newValue >= CHANGE_SERVER_COUNT) {
      setPresses(0)
      appStatusStore.toggleDevMode()
    } else {
      setPresses(newValue)
    }
  }

  function resolveSessionMode() {
    const mode = LocalStorage.getItem('SESSION_IS_DEV', false)

    const isDevSession = typeof mode === 'string' ? JSON.parse(mode) : false

    return isDevSession
  }

  async function loadMode() {
    try {
      const isDevSession = resolveSessionMode()

      appStatusStore.toggleDevMode(isDevSession)
      setLoaded(true)

      logger.log('Dev mode loaded', { isDevSession })
    } catch (err) {
      logger.error({ err })
    }
  }

  onMount(() => {
    if (readMode) {
      loadMode()
    }
  })

  return {
    onPress: () => onChangePress(numberOfPresses),
    isDevMode,
    loaded,
  }
}
