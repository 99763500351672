import { createStyles } from '@codeleap/styles'
import { EmptyPlaceholderComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'
import { textVariant } from './Text'

const createEmptyPlaceholderVariant = createStyles<EmptyPlaceholderComposition>

const iconSize = 120

export const EmptyPlaceholderStyles = {
  default: createEmptyPlaceholderVariant((theme) => ({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.gap(2),
    },
    'wrapper:loading': {
      ...theme.spacing.paddingTop(2),
    },
    title: {
      ...textVariant('h3'),
    },
    description: {
      ...textVariant('p1'),
    },
    imageWrapper: {

    },
    image: {
      width: iconSize,
      height: iconSize,
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
  })),
  compact: createEmptyPlaceholderVariant((theme) => ({
    wrapper: {
      ...theme.spacing.marginVertical(0),
      marginBottom: theme.spacing.value(6),
    },
  })),
  absolute: createEmptyPlaceholderVariant((theme) => ({
    'wrapper:loading': {
      ...theme.presets.absolute,
      ...theme.presets.whole,
      backgroundColor: theme.colors.background,
    },
    wrapper: {
      ...theme.presets.absolute,
      ...theme.presets.whole,
      backgroundColor: theme.colors.background,
    },
  })),
}

StyleRegistry.registerVariants('EmptyPlaceholder', EmptyPlaceholderStyles)
