import { make18n, useI18N } from '@codeleap/i18n'
import { Paths } from '@codeleap/types'
import { LocalStorage } from '../localStorage'

import enGB from './en-GB/en-GB.json'
import ptBR from './pt-BR/pt-BR.json'

export const I18NDictionary = {
  'en-GB': enGB,
  'pt-BR': ptBR
}

export const I18N = make18n({
  initialLocale: 'en-GB',
  persistor: {
    getLocale: () => {
      const locale = LocalStorage.getItem('LOCALE')

      return locale
    },
    setLocale: (locale) => LocalStorage.setItem('LOCALE', locale)
  },
  languageDictionary: I18NDictionary
})

type I18NKeys = Paths<typeof enGB>

export const useAppI18N = useI18N<I18NKeys>