import { AppForms, IsDevEnv, LocalStorage } from '@/config'
import { Profile } from '@/types'
import { FunctionType } from '@codeleap/types'
import { onUpdate } from '@codeleap/hooks'

type TesterData = Partial<Pick<Profile, 'email'|'last_name'|'first_name'|'password'>>

export const useTester = (onStateSync?: FunctionType<[TesterData], any>) => {
  const [tester, setTester] = LocalStorage.use('TESTER', 'default')

  onUpdate(() => {
    if (IsDevEnv && !!onStateSync) {
      onStateSync({
        ...AppForms.testers[tester],
      })
    }
  }, [tester])

  return { tester, setTester }
}
