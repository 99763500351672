import { CollapseComposition } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

const createCollapseVariant = createStyles<CollapseComposition>

export const CollapseStyles = {
  default: createCollapseVariant((theme) => ({
    'wrapper:closed': {
      pointerEvents: 'none',
      overflow: 'hidden',
    }
  })),
}

StyleRegistry.registerVariants('Collapse', CollapseStyles)
