import React, { PropsWithChildren } from 'react'
import { View, Text, Button, Drawer } from '@/components'
import { useBooleanToggle } from '@codeleap/hooks'
import { useIsMobile, useIsTablet } from '@/utils'

type ComponentControlProps = {
  control?: {
    Component: React.FC<{}>
    props: any
  }[]
  children?: React.ReactNode
  gap?: number
}

type ComponentShowCase = PropsWithChildren<{
  Preview: () => JSX.Element
  name?: string
}>

export const ComponentShowCase = (props: ComponentShowCase) => {
  const {
    Preview,
    name = 'Example',
    children,
  } = props

  const isMobile = useIsMobile()

  return (
    <View style={['column', 'flex', 'paddingHorizontal:1', 'paddingVertical:1']}>
      <Text text={name} style={['h3', 'marginBottom:3']} />

      <View style={[isMobile ? 'column' : 'row', 'gap:2', 'justifyCenter']}>
        {children ?? <Preview />}
      </View>
    </View>
  )
}

export const ComponentControl = ({ control = [], children, gap = 0 }: ComponentControlProps) => {
  const isTablet = useIsTablet()
  const [visible, toggle] = useBooleanToggle(false)

  const Controls = () => (
    <View style={['gap:2', 'column', 'flex', { maxWidth: isTablet ? '100%' : '30vw' }]}>
      {control?.map(({ Component, props }, key) => {
        return <View style={['gap:2', 'column']} key={key}><Component {...props} /></View>
      })}
    </View>
  )

  return (
    <View style={['fullWidth', 'row', 'gap:2', { breakpoints: { tabletSmall: ['column'] } }]}>
      {isTablet ? (
        <Button
          debugName='open component control'
          text='Open Component Control'
          onPress={toggle}
          style={['marginBottom:2']}
        />
      ) : null}

      {children ? (
        <View style={['column', 'flex', `gap:${gap}`]}>
          {children}
        </View>
      ) : null}

      {!isTablet && control?.length >= 1 ? <Controls /> : null}

      <Drawer
        debugName='Component show case'
        open={visible}
        toggle={toggle}
        title={'Controls'}
        position={'right'}
        size={85}
        showCloseButton
      >
        <Controls />
      </Drawer>
    </View>
  )
}
