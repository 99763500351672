import { api, IsDevEnv, Settings, LocalStorage } from '@/config'
import { useEffect, useUnmount } from '@codeleap/hooks'
import { TypeGuards } from '@codeleap/types'
import { globalState } from '@codeleap/store'
import { NetInfoState } from '@react-native-community/netinfo'

type AppStatus = 'loading' | 'done' | 'idle' | 'splash' | 'blank'

type StoreValue = {
  status: AppStatus
  isDev: boolean
  authFinished: boolean
  hasResolvedInitialAuth: boolean
  network: NetInfoState
}

export const state = globalState<StoreValue>({
  status: 'idle',
  isDev: IsDevEnv,
  authFinished: false,
  hasResolvedInitialAuth: false,
  network: {} as NetInfoState,
})

export function setHasResolvedInitialAuth(hasResolvedInitialAuth: boolean) {
  state.set({ hasResolvedInitialAuth })
}

export function set(status: StoreValue['status']) {
  state.set({ status })
}

export function toggleDevMode(newMode?: boolean) {
  let isDev = !state.value.isDev

  if (TypeGuards.isBoolean(newMode)) {
    isDev = newMode
  }

  api.setConfig({
    baseURL: isDev ? Settings.Fetch.DevelopmentURL : Settings.Fetch.ProductionURL,
  })

  LocalStorage.setItem('SESSION_IS_DEV', isDev ? true : false)

  state.set({ isDev })
}

export function authFinished(authFinished: boolean = true) {
  state.set({ status: 'splash' })

  setTimeout(() => {
    state.set({ authFinished })
  })
}

export function setAuthFinished(authFinished: boolean) {
  state.set({ authFinished })
}

export function AppStatusEffect(
  { initialStatus = 'loading',
  finalStatus = 'idle',
  forceUnmount = false
}: { initialStatus?: AppStatus; finalStatus?: AppStatus; forceUnmount?: boolean }) {
  useEffect(() => {
    state.set({ status: initialStatus })

    if (forceUnmount) state.set({ status: finalStatus })
  }, [forceUnmount])

  useUnmount(() => {
    state.set({ status: finalStatus })
  })

  return null
}
