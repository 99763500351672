import { AppImages } from '@/config'
import { Animations, AnimationsProps } from '@/components'

const animation = AppImages.loadingAnimation

export const ActivityIndicatorCP = (props: Partial<AnimationsProps> = {}) => {
  return (
    <Animations
      animation={animation}
      autoplay
      loop
      {...props}
    />
  )
}
