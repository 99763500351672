import { OSAlert } from '@/config'
import { firebase } from '@/services/firebase';
import { appStatusStore } from '@/stores';
import { TypeGuards } from '@codeleap/types'
import { useI18N } from '@codeleap/i18n'

export function useChangePassword() {
  const { t } = useI18N()

  const checkChangePassword = () => {
    OSAlert.info({
      title: t('profile.changePasswordModal.checkEmail.title'),
      body: t('profile.changePasswordModal.checkEmail.subtitle'),
    })
  }

  const onChangePassword = (email: string) => {
    OSAlert.ask({
      title: t('profile.changePasswordModal.confirmRequest.title'),
      body: t('profile.changePasswordModal.confirmRequest.body'),
      options: [
        {
          text: t('proceed'),
          onPress: () => requestPasswordReset(email, checkChangePassword)
        },
        {
          text: t('cancel'),
          style: 'outline'
        },
      ]
    })
  }

  const requestPasswordReset = async (email:string, onSuccess = null) => {
    appStatusStore.set('loading')

    try {
      await firebase.auth.sendPasswordResetEmail(email)

      setTimeout(() => {
        appStatusStore.set('done')
      }, 2000)

      if (TypeGuards.isFunction(onSuccess)) onSuccess?.()
    } catch (e) {
      await appStatusStore.set('idle')
      console.error('Password reset', e, 'Auth')
      OSAlert.error({
        title: 'Failed to send email',
        body: 'There was an error during password reset',
      })

      throw e
    }
  }

  return {
    requestPasswordReset,
    onChangePassword,
  }
}
