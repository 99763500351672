const config = {
  apiKey: 'AIzaSyBzvr9bN5M2lgzTLIo07wn3bUmmZnsMhaA',
  authDomain: 'codeleap-project-template.firebaseapp.com',
  databaseURL: 'https://codeleap-project-template.firebaseio.com',
  projectId: 'codeleap-project-template',
  storageBucket: 'codeleap-project-template.appspot.com',
  messagingSenderId: '268760770384',
  appId: '1:268760770384:web:49a825eb74a7b626d1ee55',
}

import * as _firebase from 'firebase/app';
import * as auth from 'firebase/auth';

type WithoutFirstArg<FN> = FN extends (arg: any, ...rest: infer R) => any ? (...args: R) => ReturnType<FN> : FN
type FirstArg<FN> = FN extends (arg: infer A, ...rest: any) => any ? A : never

type TAuth = typeof auth
const authInstanceFunctions:Array<keyof TAuth> = [
  'signInWithPopup',
  'signInAnonymously',
  'signInWithCustomToken',
  'signInWithEmailAndPassword',
  'signInWithPhoneNumber',
  'signInWithRedirect',
  'signInWithCredential',
  'fetchSignInMethodsForEmail',
  'onAuthStateChanged',
  'signOut',
  'sendPasswordResetEmail',
  'sendEmailVerification',
  'setPersistence',
  'createUserWithEmailAndPassword'
] 


type FirebaseAuth = {
  [K in keyof TAuth]: FirstArg<TAuth[K]> extends auth.Auth ? WithoutFirstArg<TAuth[K]> : TAuth[K]
} & ReturnType<typeof auth.getAuth>


class Firebase {
  _app: _firebase.FirebaseApp = null

  constructor() {}

  get app() {
    if(this._app) {
      return this._app
    }

    this._app = _firebase.initializeApp(config)

    return this._app
  }

  get auth() {
    const authInstance = auth.getAuth(this.app)
    const authObj = {
      ...auth,
      ...authInstance
    }
    const authProxy = new Proxy(authObj, {
      get(target, p, receiver) {
        if(authInstanceFunctions.includes(p as any)) {
          return (...args: any) => {
            return target[p](authInstance, ...args)
          }
        }

        return target[p as any]
      },
    })

    return authProxy as unknown as FirebaseAuth
  }
}

export const firebase = new Firebase()
