import React from 'react'
import { Button, Text, View } from '@/components'
import { navigation } from '@/config'

export const ForgotPasswordAfterForm = () => {
  const onTryResendEmail = () => {
    navigation.navigate('Auth.ForgotPassword')
  }

  return (
    <View style={['flex', 'column', 'gap:4']}>
      <View style={['column', 'gap:1']}>
        <Text
          style={['h1', 'extraBold']}
          text={'The email has been sent'}
        />

        <Text style={['p1']} text={'Check your email for the password reset link.'} />
      </View>

      <View style={['column', 'gap:2', 'marginTop:auto', 'alignSelfCenter', 'fullWidth']}>
        <Button
          onPress={onTryResendEmail}
          debugName={'Resend email'}
          style={['outline', 'pill', 'large', 'row', 'gap:0.5', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}>
          <Text text={'Did not arrive?'} style={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={'Resend'} style={['medium', 'color:primary3']} />
        </Button>

        <Button
          text='Go back to Login'
          onPress={() => navigation.navigate('Auth.Login')}
          debugName={'Forgot Password Page - Go back to Login press '}
        />
      </View>
    </View>
  )
}
