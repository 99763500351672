import { CodeleapQueryClient, ReactQuery } from '@codeleap/query'
import { useEffect } from '@codeleap/hooks'
import { firebase } from '../firebase'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { persistQueryClient } from '@tanstack/react-query-persist-client'

const reactQueryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours,
    },
  },
})

if (!(typeof window === 'undefined')) {
  firebase.auth.onAuthStateChanged((user) => {
    reactQueryClient.setDefaultOptions({
      queries: {
        enabled: !!user?.uid,
      },
    })
  })
}

const queryClient = new CodeleapQueryClient(reactQueryClient)

function usePersistQueryClient() {
  useEffect(() => {
    const persister = createSyncStoragePersister({
      storage: window.localStorage
    })

    persistQueryClient({
      queryClient: reactQueryClient,
      persister,
    })
  }, [queryClient])
}

export { queryClient, usePersistQueryClient }
