import { LocalStorage, React, OSAlert } from '@/config'
import { Modal, Text, View, Button, TextInput } from '@/components'
import { APIClient } from '@/services'
import { useAppStatus } from '@/utils'
import { AnyFunction } from '@codeleap/types'
import { useI18N } from '@codeleap/i18n'
import { useState } from '@codeleap/hooks'

type ReauthenticationModalProps = {
  onSubmit?: (password: string) => void
  onCancel?: AnyFunction
  visible: boolean
  toggle: AnyFunction
}

export const ReauthenticationModal = (props: ReauthenticationModalProps) => {
  const { onSubmit, onCancel } = props

  const [password, setPassword] = useState('')

  const appStatus = useAppStatus()
  const { reauthenticate } = APIClient.Session.useSession()
  const { t } = useI18N()

  const onContinue = async () => {
    try {
      await reauthenticate(password)
      props?.toggle()
      await onSubmit(password)
      LocalStorage.setItem('IN_REAUTHENTICATION', true)
    } catch (e) {
      props?.toggle()
      logger.error(e)
      OSAlert.info({
        title: 'Could not authenticate',
        body: 'Please check your credentials.',
        onDismiss: props?.toggle,
      })
    }
  }

  return (
    <Modal
      title={t('Reauthetication.title')}
      debugName={'Profile reauthentication modal'}
      showClose={false}
      closable={false}
      {...props}
      style={['centered', { body: 'gap:2' }]}
      visible={props?.visible && !appStatus.showingFeedback}
    >
      <Text
        style={['p1', 'textCenter']}
        text={t('Reauthetication.body')}
      />

      <TextInput
        value={password}
        onChangeText={setPassword}
        debugName={'Profile edit password input'}
        leftIcon={{ icon: 'key', debugName: 're-auth password icon' }}
        password
        visibilityToggle
        placeholder={t('Reauthetication.inputPlaceholder')}
        style={['noError']}
      />

      <View style={['row', 'gap:2', 'marginTop:0.5']}>
        <Button
          text={t('Reauthetication.buttonCancel')}
          debugName={'Cancel reauthentication'}
          onPress={onCancel}
          style={['flex', 'outline']}
        />
        <Button
          text={t('Reauthetication.buttonSubmit')}
          debugName={'Reauthenticate user to update profile'}
          onPress={onContinue}
          style={['flex']}
          disabled={!password.trim()}
        />
      </View>
    </Modal>
  )
}
