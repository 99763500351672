import { View, Text, Link } from '@/components'
import { StyleSheets } from '@/config'
import { StylesOf } from '@codeleap/types'

export type FooterDisclaimersProps = {
  styles?: StylesOf<StyleSheets.FooterComposition>
}

export const FooterDisclaimers = (props: FooterDisclaimersProps) => {
  const { styles } = props

  return (
    <View style={styles.disclaimersWrapper}>
      <Text style={styles.disclaimerText}>Visit us in Camden, London, UK.</Text>
      <Text style={styles.disclaimerText}>
        CodeLeap Ltd. is registered in England&nbsp;&&nbsp;Wales,
        company&nbsp;no.&nbsp;11967804.
      </Text>
      <Text style={styles.disclaimerText}>
        This website uses&thinsp;
        <Link style={styles.disclaimerText} to='/terms/license'>third-party assets and software
        </Link>
        . By using our website you agree to the&thinsp;
        <Link style={styles.disclaimerText} to='/terms/website'>
          terms of use
        </Link>
        .
      </Text>
    </View>
  )
}
