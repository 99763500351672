import { createForm } from '@codeleap/form'
import { login } from './login'

export const forgotPassword = () => {
  const loginForm = login()
  return createForm('forgotPassword', {
    email: loginForm.config.email,
    tester: loginForm.config.tester,
  })
}
