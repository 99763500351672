import { navigation } from '@/config'
import { useEffect } from 'react'

type RedirectProps<T extends AppRoute> = {
  to: T
  routeParams?: Record<RouteParams<T>, string>
}

export function Redirect<T extends AppRoute>(props: RedirectProps<T>) {
  const { to, routeParams } = props

  useEffect(() => {
    navigation.navigate<T>(to, routeParams)
  }, [])

  return null
}
