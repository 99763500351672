import { createStyles } from '@codeleap/styles'
import { OverlayComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'

const createOverlayVariant = createStyles<OverlayComposition>

export const OverlayStyles = {
  default: createOverlayVariant((theme) => ({
    wrapper: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      backgroundColor: theme.colors.neutral10,
      visibility: 'hidden',
      opacity: 0,
    },
    'wrapper:visible': {
      opacity: 0.5,
      visibility: 'visible',
      zIndex: 2,
    },
  })),
}

StyleRegistry.registerVariants('Overlay', OverlayStyles)
