import React from 'react'
import { Image, View, Text, ImageProps } from '@/components'
import { useIsMobile } from '@/utils'
import { createStyles } from '@codeleap/styles'

type PageProps = {
  title: string
  text: string
  image: ImageProps['source']
}

export const PageComponent = (props: PageProps) => {
  const { title, text, image } = props

  const isMobile = useIsMobile()

  return (
    <View style={['center', 'column', 'textCenter', 'gap:4']}>
      <View style={styles.imageWrapper}>
        <Image alt='onboarding' source={image} objectFit={isMobile ? 'cover' : 'contain'} style={styles.image} />
      </View>

      <View
        style={[
          'paddingHorizontal:4',
          'gap:2',
          'column',
          'center',
          'fullWidth',
          'flex',
          { breakpoints: { mobile: ['paddingHorizontal:2', 'gap:1'] } }]}
      >
        <Text style={['h2']} text={title} />
        <Text style={['p1']} text={text} />
      </View>
    </View>
  )
}

export const Page = React.memo(PageComponent, () => true)

const IMAGE_HEIGHT = 350

const styles = createStyles(theme => ({
  imageWrapper: {
    background: theme.colors.primary1,
    height: IMAGE_HEIGHT,
    ...theme.presets.fullWidth,
    ...theme.presets.center,
  },
  image: {
    width: '100%',
    height: IMAGE_HEIGHT,

    [theme.media.down('mobile')]: {
      width: '100vw',
    },
  },
}))
