import { Profile } from '@/types'
import { AuthErrors } from './errors'
import { useSession } from './useSession'
import { isEmailAvailable } from './views'
import { firebase } from '@/services/firebase'
import { appStatusStore } from '@/stores'

export function useEdit() {
  const { profile, updateProfile } = useSession()

  const editProfile = async (data: Partial<Profile>) => {
    appStatusStore.set('loading')

    try {
      const emailAddressChanged = data.email.trim() !== profile.email.trim()

      if (emailAddressChanged) {
        const emailAvailable = await isEmailAvailable(data.email)

        if (!emailAvailable) {
          AuthErrors.exception('auth/email-in-use')
        }

        try {
          await firebase.auth.updateEmail(firebase.auth.currentUser, data.email)
        } catch (e) {
          AuthErrors.exception(e?.code)
        }
      }

      const response = await updateProfile.mutateAsync(data)

      setTimeout(() => {
        appStatusStore.set('done')
      }, 1000)

      return response

    } catch (err) {
      appStatusStore.set('idle')
      AuthErrors.onError(err)
      throw err
    }
  }

  return { profile, editProfile }
}
