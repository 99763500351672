import React from 'react'
import { StylesOf } from '@codeleap/types'
import { useBooleanToggle } from '@codeleap/hooks'
import { useCompositionStyles } from '@codeleap/styles'
import { NavContent } from './Content'
import { ActionIcon, Drawer } from '@/components'
import { StyleSheets } from '@/config'

export type DrawerMenuProps = {
  isMobile?: boolean
  styles?: StylesOf<StyleSheets.HeaderComposition>
}

export const DrawerMenu = (props: DrawerMenuProps) => {
  const { isMobile, styles, ...rest } = props

  const [drawerOpen, toggleDrawer] = useBooleanToggle(false)

  const compositionStyles = useCompositionStyles(['menuIcon', 'drawer'], styles)

  return <>
    <Drawer
      debugName='header:drawer'
      open={drawerOpen}
      size={75}
      position='right'
      toggle={toggleDrawer}
      showCloseButton
      {...rest}
      style={compositionStyles.drawer}
    >
      <NavContent isMobile={isMobile} styles={styles} />
    </Drawer>

    <ActionIcon
      debugName='header:menuButton'
      icon='menu'
      style={compositionStyles.menuIcon}
      onPress={toggleDrawer}
    />
  </>
}
