import { I18N, IS_SSR, Settings } from '@/config'
import { APIClient } from '@/services'
import { globalState } from '@codeleap/store'
import { TypeGuards } from '@codeleap/types'

type ShareState = {
  data: ShareData
  visible: boolean
}

type ShareContent = {
  message: string
  url: string
  title: string
}

const shareState = globalState<ShareState>({ data: null, visible: false })

const toggle = (to: boolean = null) => {
  shareState.set({ visible: TypeGuards.isBoolean(to) ? to : !shareState.value.visible })
}

const getWebsite = () => {
  if (IS_SSR) return ''

  return window?.location?.origin
}

const share = async (content: ShareContent) => {
  if (!navigator) return
  
  const isMobileShare = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

  const shareData: ShareData = {
    text: content?.message,
    url: content?.url,
    title: content?.title,
  }

  const shareable = (!!navigator.share && navigator.canShare(shareData)) && isMobileShare

  if (!shareable) {
    shareState.set({ data: shareData, visible: true })
  } else {
    await navigator.share(shareData)
  }
}

export const sharePost = (post: APIClient.Post.Post) => {
  const address = `${getWebsite()}/crud/${post?.id}`

  const message = I18N.t('share.post.message', {
    appName: Settings.AppName,
    postName: post.title,
  })

  share({
    message,
    url: address,
    title: post.title,
  })
}

export const Share = {
  getWebsite,
  useShare: shareState.use,
  post: sharePost,
  toggle,
}
