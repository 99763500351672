import { Widget } from '@/utils'
import { ReactStateProps } from '@codeleap/types'
import { NumberIncrement } from '@/components'
import { useState } from 'react'

type TNumberWidget = Widget<ReactStateProps<'value', number>, {
  initialValue?: number
  onValueChange?: (value: number) => void
}>

export const NumberWidget: TNumberWidget = {
  Control({ value, setValue, label, args }) {

    function onValueChange(_value) {
      setValue(_value)
      args?.onValueChange?.(_value)
    }

    return (
      <NumberIncrement
        value={value}
        label={label}
        onValueChange={onValueChange}
        style={['noError']}
        debugName={'Number Widget ' + label}
      />
    )

  },
  handle(params) {
    const [value, setValue] = useState(params?.initialValue || 0)

    return {
      value,
      setValue,
    }
  },
}
