import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

export type ToolbarComposition = 'wrapper' | 'toolbarGroup' | 'option'

const createToolbarVariant = createStyles<ToolbarComposition>

export const ToolbarStyles = {
  default: createToolbarVariant((theme) => ({
    wrapper: {
      ...theme.spacing.padding(2),
      ...theme.spacing.gap(2),
      ...theme.presets.flex,
      ...theme.presets.justifyStart,
      ...theme.presets.alignCenter,
      flexWrap: 'wrap',
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
      ...theme.border({ color: theme.colors.neutral5, width: theme.values.borderWidth.small }),
      ...theme.presets.relative,
    },
    toolbarGroup: {
      backgroundColor: theme.colors.neutral5,
      borderRadius: theme.borderRadius.small,
      overflow: 'hidden',
    },
    option: {
      width: '100%',
      height: '100%',
      ...theme.spacing.padding(1),
      background: theme.colors.neutral3,
      ...theme.border({ color: theme.colors.neutral2, width: theme.values.borderWidth.small, directions: ['left', 'right'] }),
    },
    'option:active': {
      background: theme.colors.primary1,
    },
    'option:disabled': {},
  })),
}

StyleRegistry.registerVariants('ToolBar', ToolbarStyles)
