import { BadgeComposition } from '@codeleap/web'
import { textVariant } from './Text'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

const createBadgeVariant = createStyles<BadgeComposition>

export const BadgeStyles = {
  default: createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.sized(2),
      ...theme.presets.absolute,
      top: theme.spacing.value(0),
      left: theme.spacing.value(0),
      backgroundColor: theme.colors.destructive2,
      borderRadius: theme.borderRadius.rounded,
    },
    innerWrapper: {
      ...theme.sized(2),
      ...theme.presets.center,
      borderRadius: theme.borderRadius.rounded,
    },
    count: {
      ...textVariant('p5'),
      ...theme.presets.textCenter,
      color: theme.colors.neutral1,
    },
  })),
  border: createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.sized(2.5),
      backgroundColor: theme.colors.neutral1,
    },
    innerWrapper: {
      ...theme.sized(1.5),
      backgroundColor: theme.colors.destructive2,
    },
  })),
  'position:leftTop': createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.presets.absolute,
      top: -theme.spacing.value(0.5),
      left: -theme.spacing.value(0.5),
      zIndex: 2,
    },
  })),
  'position:leftBottom': createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.presets.absolute,
      bottom: -theme.spacing.value(0.5),
      left: -theme.spacing.value(0.5),
      zIndex: 2,
    },
  })),
  'position:rightTop': createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.presets.absolute,
      top: -theme.spacing.value(0.5),
      right: -theme.spacing.value(0.5),
      zIndex: 2,
    },
  })),
  'position:rightBottom': createBadgeVariant((theme) => ({
    wrapper: {
      ...theme.presets.absolute,
      bottom: -theme.spacing.value(0.5),
      right: -theme.spacing.value(0.5),
      zIndex: 2,
    },
  })),
}

StyleRegistry.registerVariants('Badge', BadgeStyles)
