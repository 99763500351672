import '../config/styles/theme'
import '../config/styles'
import '../components'
import '../services/api/queryClient'

import type { AppProps } from 'next/app'
import { AppWrapper } from '@/components'
import { navigation } from '@/config'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const pathname = usePathname()

  navigation.context.router = router
  navigation.context.pathname = pathname

  return (
    <AppWrapper dehydratedState={pageProps.dehydratedState}>
      <Component {...pageProps} />
      <ReactQueryDevtools />
    </AppWrapper>
  )
}
