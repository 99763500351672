import { TypeGuards } from '@codeleap/types'
import { globalState } from '@codeleap/store'

const initialState = {
  debug: false,
}

export const state = globalState(initialState)

export function toggleModal(modal: keyof typeof initialState, value?: boolean) {
  const newValue = TypeGuards.isBoolean(value) ? value : !state.value[modal]

  state.set({ [modal]: newValue })
}
