import { navigation, React, StyleSheets } from '@/config'
import { View, Button, Link, Avatar, Text } from '@/components'
import { StylesOf } from '@codeleap/types'
import { useI18N } from '@codeleap/i18n'
import { useCompositionStyles } from '@codeleap/styles'
import { APIClient } from '@/services'

export type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

export type NavContentProps = {
  isMobile?: boolean
  styles?: StylesOf<StyleSheets.HeaderComposition>
}

const links: NavigateLink[] = [
  { text: 'Components', route: 'Components.List' },
  { text: 'CRUD', route: 'Crud.Example' },
  { text: 'About', route: 'About' },
]

export const NavContent = (props: NavContentProps) => {
  const { isMobile, styles } = props
  const profile = APIClient.Session.useProfile()

  const { t } = useI18N()

  const compositionStyles = useCompositionStyles(['signInButton', 'avatar'], styles)

  const ProfileAvatar = React.useCallback(() => (
    <Avatar
      image={profile?.data?.avatar as any}
      name={[profile?.data?.first_name, profile?.data?.last_name]}
      style={compositionStyles.avatar}
      debugName={'header:avatar'}
      onPress={() => navigation.navigate('Profile.Edit')}
    />
  ), [profile])

  const RenderLink = React.useCallback(({ link }: { link: NavigateLink }) => {
    const isSelected = navigation.isCurrentRoute(link?.route)
    return (
      <Link
        text={link?.text}
        route={link?.route}
        to={link?.to}
        style={[
          styles.navItem,
          isSelected ? styles['navItem:selected'] : null,
        ]}
      />
    )
  }, [])

  return (
    <View style={styles.navContentWrapper} suppressHydrationWarning>
      {(!!profile?.isLoggedIn && isMobile) ? (
        <View style={styles.profileWrapper}>
          <ProfileAvatar />

          <View style={styles.profileInfos}>
            <Text text={profile?.data?.first_name} style={styles.firstName} />
            <Text text={profile?.data?.email} style={styles.email} />
          </View>
        </View>
      ) : null}

      {links?.map((link) => <RenderLink link={link} key={'header-' + link?.text} />)}

      {!!profile?.isLoggedIn ? null : (
        <Button
          text={t('actions.signup')}
          style={compositionStyles.signInButton}
          onPress={() => navigation.navigate('Auth.Signup')}
          debugName='header:signUpButton'
        />
      )}

      {(!!profile?.isLoggedIn && !isMobile) ? <ProfileAvatar /> : null}
    </View>
  )
}
