import { CenterWrapperComposition } from './CenterWrapper'
import { FooterComposition } from './Footer'
import { HeaderComposition } from './Header'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

export type PageComposition =
  'wrapper' |
  'innerWrapper' |
  'backgroundImage' |
  'innerWrapper:backgroundImage' |
  `content${Capitalize<CenterWrapperComposition>}` |
  `footer${Capitalize<FooterComposition>}` |
  `header${Capitalize<HeaderComposition>}`

const createPageVariant = createStyles<PageComposition>

export const PageStyles = {
  default: createPageVariant((theme) => ({
    wrapper: {
      ...theme.presets.column,
      minHeight: '100svh',
      width: '100%',
      backgroundColor: theme.colors.background,
    },
    'contentWrapper': {
      flex: 1,
      minHeight: '90svh',
      zIndex: 1,
    },
    'contentInnerWrapper': {
      ...theme.presets.column,
    },
    'innerWrapper': {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
    },
    'innerWrapper:backgroundImage': {
      ...theme.presets.absolute,
    },
    backgroundImage: {
      width: '100vw',
      minHeight: '100svh',
      position: 'absolute',
      objectFit: 'contain',
    },
  })),
  fullContentViewport: createPageVariant((theme) => ({
    contentWrapper: {
      minHeight: '100svh',
    },
    contentInnerWrapper: {
      minHeight: '100svh',
    },
  })),
}

StyleRegistry.registerVariants('Page', PageStyles)
