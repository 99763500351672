import { AppImages } from '@/config'

export const onboardingPages = [
  {
    title: 'A beautiful headline talking about the app',
    text: 'Lorem ipsum dolor sit amet consectetur. Fermentum adipiscing ultricies pellentesque pretium vitae velit eget adipiscing non.',
    image: AppImages.Onboarding1,
  },
  {
    title: 'A beautiful headline talking about the app',
    text: 'Lorem ipsum dolor sit amet consectetur. Fermentum adipiscing ultricies pellentesque pretium vitae velit eget adipiscing non.',
    image: AppImages.Onboarding2,
  },
]
