import { useMemo } from 'react'
import { StyleSheets, StyleRegistry } from '@/config'
import { matchInitialToColor } from '@codeleap/utils'
import { TypeGuards, WebInputFile } from '@codeleap/types'
import { Image, View, Text, Touchable, Badge, Icon, ImageProps } from '@/components'
import { BadgeComponentProps, BaseCropProps, CropPicker, TouchableProps, useFileInput, useStylesFor } from '@codeleap/web'
import { StyledProp, useNestedStylesByKey, StyledComponent, AppIcon } from '@codeleap/styles'

export type AvatarProps =
  Omit<TouchableProps, 'name' | 'style'> &
  BadgeComponentProps &
  {
    style?: StyledProp<StyleSheets.AvatarComposition>
    onChange?: (picture: WebInputFile) => void
    image?: ImageProps['source']
    badge?: boolean
    name?: string[] | string
    debugName: string
    firstNameOnly?: boolean
    text?: string
    description?: string
    icon?: AppIcon
    badgeIcon?: AppIcon
    cropPickerProps?: Partial<BaseCropProps>
  }

export const Avatar: StyledComponent<typeof StyleSheets.AvatarStyles, AvatarProps> = (props) => {
  const {
    debugName,
    name = '',
    firstNameOnly = true,
    image,
    style,
    icon,
    badgeIcon,
    onChange,
    onPress,
    text = '',
    description,
    badge = false,
    badgeProps,
    cropPickerProps,
    ...viewProps
  } = props as AvatarProps

  const styles = useStylesFor(Avatar.styleRegistryName, style)
  const input = useFileInput()

  const editable = !!onChange
  const hasImage = !!image

  const handlePress = async () => {
    if (!editable) {
      onPress?.()
    } else {
      const files = await input.openFilePicker()
      if (!files.length) return
      onChange?.(files[0])
    }
  }

  const { initials, randomColor } = useMemo(() => {
    const [first = '', last = ''] = TypeGuards.isString(name) ? name.split(' ') : name
    const initials = [first[0]]

    if (!firstNameOnly) {
      initials.push(last[0])
    }

    return {
      initials: initials.join(' '),
      randomColor: matchInitialToColor(first[0]),
    }
  }, [name, firstNameOnly])

  const RenderContent = () => {
    if (hasImage) return <Image source={image} style={styles.image} objectFit='cover' alt='avatar' />
    if (icon) return <Icon name={icon} style={styles.icon} debugName='Avatar icon' />
    return <Text text={text || initials} style={styles.initials} />
  }

  const hasBackgroundColor = !!styles?.touchable?.backgroundColor

  const badgeStyles = useNestedStylesByKey('badge', styles)

  return (
    <View style={styles.wrapper}>
      <CropPicker
        debugName='Avatar CropPicker'
        // @ts-expect-error
        ref={input.ref}
        targetCrop={cropPickerProps}
      />

      <Touchable
        debugName={'Avatar ' + debugName}
        onPress={handlePress}
        {...viewProps}
        style={[
          styles.touchable,
          !hasBackgroundColor ? { backgroundColor: randomColor } : null,
        ]}
      >
        <RenderContent />

        {!description ? null : (
          <View style={styles.descriptionOverlay}>
            <Text text={description} style={styles.description} />
          </View>
        )}

        <Badge
          badge={badge}
          {...badgeProps}
          style={badgeStyles}
          debugName='Avatar Badge'
        />
      </Touchable>

      {badgeIcon ? (
        <Touchable
          debugName={`${debugName} badge icon`}
          style={styles.badgeIconWrapper}
          onPress={onPress}
        >
          <Icon
            name={badgeIcon}
            style={styles.badgeIcon}
            debugName='Avatar badge'
          />
        </Touchable>
      ) : null}
    </View>
  )
}

Avatar.styleRegistryName = 'Avatar'
Avatar.elements = ['wrapper', 'image', 'initials', 'description', 'icon', 'badgeIcon', 'touchable']

StyleRegistry.registerComponent(Avatar)
