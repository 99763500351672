import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

export type CardBaseParts = 'wrapper' | 'innerWrapper'
export type CardBaseState = 'pressable'

export type CardBaseComposition = CardBaseParts | `${CardBaseParts}:${CardBaseState}`

const createCardBaseVariant = createStyles<CardBaseComposition>

export const CardBaseStyles = {
  default: createCardBaseVariant((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.padding(2),
      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignStart,
    },
    innerWrapper: {
      ...theme.presets.column,
    },
    'wrapper:pressable': {
      cursor: 'pointer',
      transition: 'opacity 0.2s',

      '&:hover': {
        opacity: 0.8,
      },
    },
  })),
  'card:elevated': createCardBaseVariant(theme => ({
    wrapper: {
      ...theme.effects.light,
    },
  })),
  'card:post': createCardBaseVariant(theme => ({
    wrapper: {
      ...theme.effects.thin,
      ...theme.presets.fullWidth,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(2),
    },
  })),
}

StyleRegistry.registerVariants('CardBase', CardBaseStyles)
