import { ReactQuery } from '@codeleap/query'
import { I18NProvider } from '@codeleap/i18n'
import { GlobalContextProvider } from '@codeleap/hooks'
import { Settings, React, I18N, logger } from '@/config'
import { APIClient } from '@/services'
import { DebugModal, GlobalAlert, GlobalStyle, ShareModal, Onboarding, AppStatusOverlay } from '@/components'
import { HydrationBoundary } from '@tanstack/react-query'

const App = ({ children }: React.PropsWithChildren) => {
  APIClient.Session.useSession(true)

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <GlobalAlert />
      {Settings.Environment.IsDev ? <DebugModal /> : null}
      <Onboarding />
      <ShareModal />
    </>
  )
}

export const AppWrapper = ({ children, dehydratedState }: React.PropsWithChildren<{ dehydratedState: any }>) => {
  APIClient.usePersistQueryClient()

  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <HydrationBoundary state={dehydratedState}>
        <GlobalContextProvider isBrowser settings={Settings} logger={logger}>
          <I18NProvider i18n={I18N}>
            <App>
              {children}
            </App>
          </I18NProvider>
        </GlobalContextProvider>
      </HydrationBoundary>
    </ReactQuery.QueryClientProvider>
  )
}
