import { Profile } from '@/types'
import { AuthErrors } from './errors'
import { QueryKeys } from './queryKeys'
import { isEmailAvailable } from './views'
import { firebase } from '@/services/firebase'
import { appStatusStore } from '@/stores'

export function useLogin() {
  const login = async (data:Partial<Profile>) => {
    appStatusStore.set('loading')

    try {
      const { email, password } = data

      const emailAvailable = await isEmailAvailable(email)

      if (emailAvailable) {
        AuthErrors.exception('auth/email-not-found')
      }

      await firebase.auth.signInWithEmailAndPassword( email.trim(), password.trim())

      await QueryKeys.me.refresh()

      appStatusStore.set('done')
      return true

    } catch (err) {
      AppStatus.set('idle')
      AuthErrors.onError(err, 'login')
      return false
    }
  }

  return login
}
