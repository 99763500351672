import { capitalize, faker } from '@codeleap/utils'
import { IsDevEnv } from '../Settings'

const fakeEmail = `tester+${faker.animal()}_${faker.number(0, 100)}@codeleap.co.uk`
const fakeFirst = `${faker.name()}`
const fakeLast = `${faker.lastName()}`
const fakeBirthday = new Date(1980, 0o3, 21)

export const fakeProfile = {
  email: IsDevEnv ? fakeEmail : '',
  password: IsDevEnv ? 'pppppp' : '',
  repeatPassword: IsDevEnv ? 'pppppp' : '',
  avatar: IsDevEnv ? null : null,
  first_name: IsDevEnv ? fakeFirst : '',
  last_name: IsDevEnv ? fakeLast : '',
  birthday: IsDevEnv ? fakeBirthday : null,
}

const _testers = {
  default: {
    email: 'tester@codeleap.co.uk',
    password: 'pppppp',
  },
  random: fakeProfile,
}

const mailList = ['paulo', 'gabriel', 'felipe', 'victor', 'vini', 'fernando', 'leo', 'lucas', 'kenzo'].sort((a, b) => a.localeCompare(b))

mailList.forEach(person => {
  _testers[person] = {
    email: `${person}@codeleap.co.uk`,
    password: 'pppppp',
    first_name: capitalize(person),
    last_name: 'Codeleap',
  }
})

export const testers = _testers

export const testerOptions = Object.keys(_testers).map((testerId) => {
  return {
    value: testerId,
    label: capitalize(testerId),
  }
})
