import React from 'react'
import { imageIcons } from './exports'

export const iconImages = imageIcons

type IconsType = {
  [Property in keyof typeof iconImages]: React.FC
}

export type AppIcon = keyof IconsType

const applyFill: AppIcon[] = [
  'apple',
  'bookmark-filled',
  'heart-filled',
]

const ignoreStyles: AppIcon[] = [
  'google',
  'facebook',
  'twitter',
  'telegram',
  'whatsapp'
]

export const RenderIcon = ({ path, name = '', style = {}, ...otherProps }: any) => {
  const { size: stylesSize, width, height, color: stylesColor, ...otherStyles } = (otherProps?.forceStyle ?? style)
  const { size: propsSize, color: propsColor } = otherProps

  const h = !propsSize ? stylesSize ?? height : propsSize
  const w = !propsSize ? stylesSize ?? width : propsSize

  const styles = {
    ...otherStyles,
    height: h,
    width: w,
    color: propsColor ?? stylesColor,
    '& > *': {
      maxHeight: h,
      maxWidth: w,
    }
  }

  if (typeof path == 'object' && !!path?.default) {
    path = path.default
  }

  if (typeof path === 'function') {
    const isApplyFill = applyFill.includes(name as any)

    const isIgnoreStyle = ignoreStyles.includes(
      name as any,
    )

    let forceStroke: object = {
      '& > *': {
        stroke: `${styles.color} !important`,
      },
    }

    if (isIgnoreStyle) {
      forceStroke = null
    } else if (isApplyFill) {
      forceStroke = {
        '& > *': {
          fill: `${styles.color} !important`,
          stroke: `${styles.color} !important`,
        },
      }
    }

    const Component = path

    return <Component
      size={propsSize}
      color={propsColor}
      {...otherProps}
      css={[styles, forceStroke]}
    />
  }

  const appliedStyles = {
    height: styles.height,
    width: styles.width,
    fontSize: styles.height || styles.width,
    tintColor: styles.color || null,
    color: styles.color || null,
  }

  return <img src={path} css={appliedStyles} />
}

export const IconNames = Object.keys(iconImages) as AppIcon[]

export const Icons = Object.fromEntries(
  Object.entries(iconImages).map(([iconName, iconPath]) => {
    return [iconName, (props) => <RenderIcon path={iconPath} name={iconName} {...props} />]
  }),
) as IconsType
