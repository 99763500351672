import { ComponentControl, ComponentShowCase, Navigator, View, ComponentExampleLayout } from '@/components'

const Menu = () => {
  return <View style={['column', 'gap:2', 'fullWidth', 'flex']}>
    <Navigator.Group>
      <Navigator.Button to='Components.Navigation.Home' exactRoute text='Index' />
      <Navigator.Button to='Components.Navigation.Page1' text='Page 1' />
      <Navigator.Button to='Components.Navigation.Page2' routeParams={{ id: 1 }} text='Page 2' />
    </Navigator.Group>

    <Navigator.Section debugName='example' title='Hello world' collapsible>
      <Navigator.Button to='Components.Navigation.Home' exactRoute text='Index' />
      <Navigator.Button to='Components.Navigation.Page1' text='Page 1' />
      <Navigator.Button to='Components.Navigation.Page2' routeParams={{ id: 1 }} text='Page 2' style={['lastItem:collapsible']} />

      <Navigator.Section debugName='example' title='Hello world' style={['marginTop:1', 'marginBottom:1']}>
        <Navigator.Button to='Components.Navigation.Home' exactRoute text='Index' />
        <Navigator.Button to='Components.Navigation.Page1' text='Page 1' />
        <Navigator.Button to='Components.Navigation.Page2' routeParams={{ id: 1 }} text='Page 2' />
      </Navigator.Section>
    </Navigator.Section>

    <Navigator.Section debugName='example' title='Hello world again'>
      <Navigator.Button to='Components.Navigation.Home' exactRoute text='Index' />
      <Navigator.Button to='Components.Navigation.Page1' text='Page 1' />
      <Navigator.Button to='Components.Navigation.Page2' routeParams={{ id: 1 }} text='Page 2' />
    </Navigator.Section>
  </View>
}

export const NavigationPageLayout = ({ children, ...rest }: any) => {
  const _Preview = () => (
    <ComponentControl>
      <Navigator.Container style={['list']} {...rest}>
        <View style={['row', 'gap:2', { breakpoints: { tabletSmall: ['column', 'fullWidth'] }}]}>
          <Menu />
          {children}
        </View>
      </Navigator.Container>
    </ComponentControl>
  )

  return <ComponentExampleLayout>
    <ComponentShowCase
      Preview={_Preview}
      name='Navigation'
      {...rest}
    />
  </ComponentExampleLayout>
}
