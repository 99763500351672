import { StyleRegistry } from '@/config'
import { Redirect } from './Redirect'
import { NavigationButton } from './components/Button'
import { Group } from './components/Group'
import { Section } from './components/Section'
import { NavigationContainer } from './useNavigation'

export const Navigator = {
  Container: NavigationContainer,
  Button: NavigationButton,
  Section,
  Redirect,
  Group,
}

export const NavigationComponent = () => null

NavigationComponent.elements = [
  'section',
  'collapse',
  'title',
  'item',
  'title',
  'group',
]

NavigationComponent.styleRegistryName = 'Navigation'

StyleRegistry.registerComponent(NavigationComponent)

export * from './useNavigation'
