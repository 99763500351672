import React from 'react'
import { Button, Text, View } from '@/components'
import { navigation } from '@/config'
import { toggleOnboarding } from '@/utils'

export const VerifyEmailAfterForm = () => {
  const onContinue = () => {
    navigation.navigate('Home')
    toggleOnboarding(true)
  }

  return (
    <View style={['flex', 'column']}>
      <Text
        style={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={'Congratulations!!!'}
      />
      <Text
        style={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={"You've successfully verified your email address."}
      />

      <Button
        text={'Continue'}
        onPress={onContinue}
        debugName={'Open email'}
        style={['marginHorizontal:auto', 'fullWidth', 'large', 'marginTop:auto']}
      />
    </View>
  )
}
