import React from 'react'
import { Modal, Pager, ActionIcon, Button, View } from '@/components'
import { toggleOnboarding, useAppStatus, useIsMobile, useOnboarding } from '@/utils'
import { Page } from './Page'
import { onboardingPages } from './pages'
import { createStyles } from '@codeleap/styles'

export const Onboarding = () => {
  const { page, visible, next, previous } = useOnboarding()
  const appStatus = useAppStatus()
  const isMobile = useIsMobile()

  const isFirst = page <= 0

  const show = visible && !appStatus.showingFeedback && !appStatus.showingSplash

  return (
    <Modal
      showClose={false}
      style={[isMobile && 'fullscreen', 'centered', { box: styles.modalBox, body: styles.modalBody }]}
      visible={show}
    >
      <ActionIcon
        debugName={`onboarding:closeButton`}
        name={'x'}
        style={['minimal', 'primary3', 'alignSelfEnd', 'absolute', 'iconSize:3', styles.close]}
        onPress={() => toggleOnboarding(false)}
      />

      {/* @ts-ignore */}
      <Pager
        debugName='pager:onboarding'
        disableSwipe
        dots
        dotsDisabled
        page={page}
        onChange={() => null}
        style={[styles.pagerWrapper, { wrapper: styles.pager }]}
        footer={
          <View
            style={[
              'fullWidth',
              'center',
              'paddingHorizontal:4',
              'paddingTop:4',
              'marginBottom:4',
              'gap:2',
              {
                breakpoints: { mobile: ['paddingHorizontal:2', 'marginBottom:2', 'paddingTop:2', 'column'] },
              },
            ]}
          >
            <Button
              style={['pill', 'padding:1', 'fullWidth']}
              debugName='onboardingPager:button'
              text={'Previous'}
              onPress={previous}
              disabled={isFirst}
              debounce={1000}
            />
            <Button
              style={['pill', 'padding:1', 'fullWidth']}
              debugName='onboardingPager:button'
              text={'Next'}
              onPress={next}
              debounce={1000}
            />
          </View>
        }
      >
        {onboardingPages.map((page, index) => {
          return <Page key={'onboardingPager-' + page.title + index} {...page} />
        })}
      </Pager>
    </Modal>
  )
}

const MODAL_WIDTH = 700

const styles = createStyles(theme => ({
  modalBox: {
    ...theme.spacing.padding(0),
    borderRadius: theme.borderRadius.medium,
    width: MODAL_WIDTH,
    maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,
    [theme.media.down('mobile')]: {
      position: 'fixed',
      overflowY: 'auto',
      ...theme.presets.fullWidth,
      height: '100%',
      borderRadius: 0,
      maxWidth: `100vw`,
    },
  },
  modalBody: {
    [theme.media.down('mobile')]: {
      height: '100%',
      maxHeight: 'none',
    },
  },
  close: {
    ...theme.presets.absolute,
    top: theme.spacing.value(4),
    right: theme.spacing.value(4),
    zIndex: 1,
  },
  pager: {
    paddingBottom: theme.spacing.value(4),

    [theme.media.down('mobile')]: {
      ...theme.presets.justifySpaceBetween,
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
      paddingBottom: theme.spacing.value(2),
    },
  },
  pagerWrapper: {
    [theme.media.down('mobile')]: {
      height: '100svh',
      position: 'fixed',
      overflowY: 'auto',
    },
  },
}))
