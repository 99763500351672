import React from 'react'
import { ActivityIndicator, View } from '@/components'

const LoadingScreenComponent = ({ height = '100svh' }) => {
  return (
    <View style={['fullViewport', 'backgroundColor:neutral1', 'center', { height }]}>
      <ActivityIndicator debugName='loadingScreen:indicator' />
    </View>
  )
}

export const LoadingScreen = React.memo(LoadingScreenComponent, () => true)
