import * as firebase from 'firebase/auth'

import { useState } from 'react'

type FBInstance = ReturnType<typeof firebase.getAuth>

export const useFirebase = () => {
  const [_firebase, _setFirebase] = useState<FBInstance>(firebase.getAuth)

  return _firebase
}
